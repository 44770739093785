import { Application } from "./model/Application";

export const defaultApplications: Application[] = [
  {
    id: "askArchie",
    name: "Ask Archie",
    launchUrl: "https://archie.hyperaptive.io/",
  },
  {
    id: "aptiveAcrossAmerica",
    name: "Aptive Across America",
    launchUrl: "https://acrossamerica.hyperaptive.io/",
  },
];

export const applications: Application[] = [
  {
    id: "peopleOperations",
    name: "People Operations",
    launchUrl: "",
  },
  {
    id: "projectsReport",
    name: "Projects Report",
    launchUrl: "https://dash.hyperaptive.io/projectbudget",
  },
  {
    id: "dealTracker",
    name: "Deal Tracker",
    launchUrl: "https://dash.hyperaptive.io/dealperformance/",
  },
  {
    id: "accountPerformance",
    name: "Account Performance",
    launchUrl: "https://dash.hyperaptive.io/accountperformance/",
  },
  {
    id: "vendorPayments",
    name: "Vendor Payments",
    launchUrl: "https://www.tools.hyperaptive.io/vendor_payments",
  },
];
