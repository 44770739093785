"use client";

import { useContext, useEffect, useState } from "react";
import { ExternalLink, Layout, Search, Users } from "lucide-react";
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import {
  DropdownMenu,
  DropdownMenuTrigger,
} from "./components/ui/dropdown-menu";
import { applications } from "./apps";
import Cookies from "js-cookie";
import logo from "./assets/aptive_logo.png";
import { Avatar, AvatarFallback, AvatarImage } from "./components/ui/avatar";
import { Link } from "react-router-dom";
import UserContext from "./context/userContext";

interface LayoutProps {
  children: React.ReactNode;
}

export default function Home({ children }: LayoutProps) {
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <main className="flex-1 overflow-y-auto p-6">
          {/* <SearchAndAddSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} /> */}
          {/* <ApplicationGrid applications={filteredApplications} />
          <UserAccountManagement/>  */}
          {children}
        </main>
      </div>
    </div>
  );
}

function Sidebar() {
  const { userProfile } = useContext(UserContext);

  const [isAdmin, setIsAdmin] = useState(false);
  useEffect(() => {
    setIsAdmin(userProfile.isAdmin);
  }, [userProfile.isAdmin]);

  return (
    <div className="w-64 bg-[#1b365d] text-white p-6">
      <h1 className="text-2xl font-bold mb-8">Welcome</h1>
      <nav>
        <ul className="space-y-4">
          <SidebarItem
            icon={<Layout className="h-5 w-5" />}
            label="Dashboard"
            path="dashboard"
          />
          {isAdmin && (
            <SidebarItem
              icon={<Users className="h-5 w-5" />}
              label="User Access Management"
              path="userAccess"
            />
          )}
        </ul>
      </nav>
    </div>
  );
}

function SidebarItem({
  icon,
  label,
  path,
}: {
  icon: React.ReactNode;
  label: string;
  path: string;
}) {
  return (
    <li>
      <Link
        className="flex items-center space-x-2 hover:text-gray-300"
        to={path}
      >
        {icon} <span>{label}</span>
      </Link>
    </li>
  );
}

function Header() {
  return (
    <header className="bg-white shadow-sm">
      <div className="flex items-center justify-between px-6 py-4">
        <h2 className="text-2xl font-semibold text-gray-800">
           Welcome
        </h2>
        <img src={logo} className="App-logo" alt="logo" />
        <div className="flex items-center space-x-4">
          <UserMenu />
        </div>
      </div>
    </header>
  );
}

function UserMenu() {
  const [user, setUser] = useState();

  const { updateUserProfile } = useContext(UserContext);

  const serverUrl = process.env.REACT_APP_URL;
  const adminUsers = process.env.REACT_APP_ADMINS_USERS?.split(',') || [];
  const [userImage, setuserImage] = useState("");

  useEffect(() => {
    const fetchUserProfile = async (accessToken: string) => {
      var options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer  ${accessToken}`,
        },
      };
      await fetch("https://graph.microsoft.com/v1.0/me", options).then(
        (response) => {
          response.json().then(async (user) => {
            setUser(user);
            if (
              user?.mail?.includes("admin") ||
              adminUsers?.includes(user.id)
            ) {
              await fetch(`${serverUrl}/admin/token`).then((resp) => {
                resp.json().then((resp) => {
                  localStorage.setItem("admin_token", resp.token.access_token);
                  updateUserProfile({
                    isAdmin: true,
                    user: user,
                  });
                });
              });
            }
          });
        }
      );
      await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", options).then(
        async resp => {
          const blob = await resp.blob();
          const imageUrl = URL.createObjectURL(blob);
          setuserImage(imageUrl)
        }
      ).catch(error => {
        setuserImage("https://github.com/shadcn.png")
      })
    };
    const cookieToken = Cookies.get("access_token");
    if (cookieToken) {
      let accessToken: string = JSON.parse(cookieToken);
      accessToken = validateRefreshToken(accessToken);
      localStorage.setItem("access-token", accessToken);
      fetchUserProfile(accessToken);
    }

    function validateRefreshToken(accessToken: string) {
      const tokenExpiry = Cookies.get("expires_at");
      const current = new Date().toISOString();
      if (current < JSON.stringify(tokenExpiry)) {
        const refresh_token = Cookies.get("refresh_token");
        fetch(`${serverUrl}/refresh?refresh_token=${refresh_token}`).then(
          (resp) => {
            resp.json().then((token) => (accessToken = token.token));
          }
        );
      }
      return accessToken;
    }
    // }
  }, []);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="flex items-center space-x-2">
          <Avatar>
            <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" />
            <AvatarFallback>JD</AvatarFallback>
          </Avatar>
          <span>{user?.displayName}</span>
        </Button>
      </DropdownMenuTrigger>
    </DropdownMenu>
  );
}
