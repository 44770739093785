import React, { useState } from "react";
import Home from "./Home";
import "./App.css";
import "./index.css";
import ProtectedRoute from "./util/ProtectedRoute";
import UserAccountManagement from "./userAcessMgmt";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";

const App: React.FC = () => {
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const handleLogin = () => {
    setIsAuth(true);
  };

  return (
    <BrowserRouter>
      <Home>
        <Routes>
          {/* Login Route */}
          <Route path="/login" element={<Login onLogin={handleLogin} />} />

          {/* Protected Routes */}
          <Route
            path="/"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/userAccess"
            element={
              <ProtectedRoute isAuth={isAuth}>
                <UserAccountManagement />
              </ProtectedRoute>
            }
          />
          {/* Redirect unknown routes to login */}
          <Route path="*" element={<Navigate to={isAuth ? "/" : "/login"} />} />
        </Routes>
      </Home>
    </BrowserRouter>
  );
};

export default App;
