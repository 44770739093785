"use client";

import { useEffect, useState } from "react";
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./components/ui/dialog";
import { Checkbox } from "./components/ui/checkbox";
import { Search, Edit2 } from "lucide-react";
import { applications } from "./apps";

interface User {
  id: number;
  displayName: string;
  mail: string;
}

interface UserPermissions {
  [key: string]: boolean;
}

export default function UserAccountManagement() {
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [permissions, setPermissions] = useState<UserPermissions>({});
  const [nextLink, setNextLink] = useState('https://graph.microsoft.com/v1.0/users');
  const [loading, setLoading] = useState(false);
  const serverUrl = process.env.REACT_APP_URL;
  const GRAP_API_URL = "https://graph.microsoft.com/v1.0/users";

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if(!nextLink) return;
        let token = localStorage.getItem("admin_token");
        if (!token) {
          const tokenResponse = await fetch(`${serverUrl}/admin/token`)
          const tokenData = await tokenResponse.json();
          token = tokenData?.token.access_token;
          localStorage.setItem("admin_token", token);
        }

        const response = await fetch(nextLink, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        setUsers((prevUsers) => [...prevUsers, ...data.value]); // Append new users
        setNextLink(data["@odata.nextLink"] || null);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUserData();
  }, [nextLink]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = users.filter(
    (user) =>
      user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.mail?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openPermissionsDialog = async (user: User) => {
    setSelectedUser(user);
    // In a real application, you would fetch the user's current permissions here
    await fetch(`${serverUrl}/user/${user.id}`).then((resp) => {
      resp.json().then((res) => {
        res.result?.applications.map((app) =>
          handlePermissionChange(app, true)
        );
        setIsDialogOpen(true);
      });
    });
    setPermissions({});
  };

  const handlePermissionChange = (app: string, checked: boolean) => {
    setPermissions((prev) => ({ ...prev, [app]: checked }));
  };

  const savePermissions = () => {
    const selectedPermissions = Object.keys(permissions).filter(
      (key) => permissions[key] === true
    );
    const data = {
      userId: selectedUser?.id,
      email: selectedUser?.mail,
      applications: selectedPermissions,
    };

    fetch(`${serverUrl}/updateUser`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        console.log("User Updated");
        setIsDialogOpen(false);
      })
      .catch((err) => {
        console.log("Error updating the user");
      });
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">User Account Management</h1>
      <div className="flex items-center mb-4">
        <Search className="w-5 h-5 mr-2 text-gray-500" />
        <Input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={handleSearch}
          className="max-w-sm"
        />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredUsers.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.displayName}</TableCell>
              <TableCell>{user.mail}</TableCell>
              <TableCell>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => openPermissionsDialog(user)}
                >
                  <Edit2 className="w-4 h-4 mr-2" />
                  Edit Permissions
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              Edit Permissions for {selectedUser?.displayName}
            </DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {applications.map((app) => (
              <div key={app.id} className="flex items-center space-x-2">
                <Checkbox
                  id={app.id}
                  checked={permissions[app.id] || false}
                  onCheckedChange={(checked) =>
                    handlePermissionChange(app.id, checked as boolean)
                  }
                />
                <label
                  htmlFor={app.id}
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {app.name}
                </label>
              </div>
            ))}
          </div>
          <DialogFooter>
            <Button
              className="w-full bg-[#1b365d] hover:bg-[#00407A]"
              onClick={savePermissions}
            >
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
