import { useContext, useEffect, useState } from "react";
import { applications, defaultApplications } from "./apps";
import { ExternalLink, Search } from "lucide-react";
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import UserContext from "./context/userContext";
import type { Application } from "./model/Application";

const Dashboard: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const serverUrl = process.env.REACT_APP_URL;
  const [apps, setApps] = useState<Application[]>([]);
  const { userProfile } = useContext(UserContext);
  const filteredApplications = apps.filter((app) =>
    app.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    const getUserApps = async () => {
      await fetch(`${serverUrl}/user/${userProfile.user.id}`).then((resp) => {
        resp.json().then((res) => {

          setApps([
            ...defaultApplications,
            ...applications.filter((item1) =>
              res.result?.applications.some((item2) => item1.id === item2)
            ),
          ]);
        });
      });
    };
    getUserApps();
  }, [userProfile.user]);

  return (
    <>
      <SearchAndAddSection
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <ApplicationGrid applications={filteredApplications} />
    </>
  );
};

function SearchAndAddSection({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}) {
  return (
    <div className="mb-6 flex items-center justify-between">
      <div className="relative w-64">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
        <Input
          type="search"
          placeholder="Search applications"
          className="pl-8"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
    </div>
  );
}

function ApplicationGrid({
  applications,
}: {
  applications: typeof applications;
}) {
  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      {applications.map((app) => (
        <ApplicationCard key={app.id} app={app} />
      ))}
    </div>
  );
}

function ApplicationCard({ app }: { app: (typeof applications)[0] }) {
  return (
    <div className="bg-white shadow-sm rounded-lg overflow-hidden">
      <div className="p-6">
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{app.name}</h3>
        <LaunchButton url={app.launchUrl} />
      </div>
    </div>
  );
}

function LaunchButton({ url }: { url: string }) {
  const token = localStorage.getItem("access-token");
  url = token ? `${url}?token=${token}` : url;
  return (
    <Button
      className="w-full bg-[#1b365d] hover:bg-[#00407A]"
      onClick={() => window.open(url, "_top")}
    >
      <ExternalLink className="mr-2 h-4 w-4" />
      Launch
    </Button>
  );
}

export default Dashboard;
