import React, { useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import logo from "./assets/aptive_logo.png";
import "./App.css";
import "./index.css";
import { useNavigate } from "react-router-dom";

interface LoginProps {
  onLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const serverUrl = process.env.REACT_APP_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const cookieToken = Cookies.get("access_token");
    if (cookieToken) {
      onLogin();
      navigate("/");
    }
  }, []);

  const handleLogin = () => {
    window.location.href = `${serverUrl}/auth/login`;
  };

  const handleLogout = () => {
    window.location.href = `${serverUrl}/auth/logout`;
  };

  return (
    <>
      <div className="App">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="App-header">
          <h1>Welcome to aptive internal tools home</h1>
          <div>
            <button className="button" onClick={handleLogin}>
              Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
