import React, { createContext, useContext, useState } from "react";

const UserContext = createContext(null);
export default UserContext;

interface UserProfile {
  isAdmin: boolean;
  user: object;
}

const DEFAULT_USER_PROFILE: UserProfile = {
  isAdmin: false,
  user: {},
};

export const UserProvider = (props: any) => {
  const [userProfile, setUserProfile] =
    useState<UserProfile>(DEFAULT_USER_PROFILE);

  const updateUserProfile = (updatedProfile: UserProfile) =>
    setUserProfile({ ...userProfile, ...updatedProfile });
  return (
    <>
      <UserContext.Provider
        value={{
          userProfile: userProfile,
          updateUserProfile,
        }}
      >
        {props.children}
      </UserContext.Provider>
    </>
  );
};
